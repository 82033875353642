import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const Title = styled.div`
  display: flex;
  flex-direction: column;

  .id-empresa {
    color: #374151;
    font-size: 0.75rem;
    font-weight: 400;
    margin: 0;
  }

  .nome-empresa {
    font-weight: bold;
    font-size: 1.25rem
  }

`