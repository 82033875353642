import { Col, Collapse, Row } from "antd"
import React from "react"
import GrupoCards from "../../../../../../components/GrupoCards"
import { formatFaturamentoDiarioForTable } from "../../faturamentoDiarioHelper"
import { GraficoFaturamentoDiario } from "./Grafico"
import { TabelaFaturamentoDiario } from "./Tabela"

export default function FaturamentoDiario({ faturamentoDiario }) {

  return (
    <Row gutter={[12, 16]}>
      <Col md={24} lg={24} xs={24} sm={24} xl={24}>
        <GrupoCards titulo={"Gráfico - Faturamento diário"}>
          <GraficoFaturamentoDiario data={faturamentoDiario} />
        </GrupoCards>
      </Col>
      <Col md={24} lg={24} xs={24} sm={24} xl={24}>
        <GrupoCards titulo={"Tabela - Faturamento diário"}>
          <Collapse bordered={false} ghost >
            <Collapse.Panel header="Clique para expandir ou diminuir os dados" >
              <TabelaFaturamentoDiario data={faturamentoDiario} />
            </Collapse.Panel>
          </Collapse>
        </GrupoCards>
      </Col>
    </Row>
  )
}