import React, { useCallback, useEffect, useState } from "react"
import { Drawer, Spin, notification } from "antd"
import { useAuth } from "../../../../../../hooks/useAuth"
import TituloTela from "../../../../../../components/TituloTela"
import api from "../../../../../../service/api"
import { Container, Content, Title } from "./styles"
import { GraficoFaturamentoDiario } from "../FaturamentoDiario/Grafico"
import { TabelaFaturamentoDiario } from "../FaturamentoDiario/Tabela"
import GrupoCards from "../../../../../../components/GrupoCards"

export default function DetalheFaturamentoEmpresa({
  empresaFilial,
  isOpen,
  closeFn,
  periodo
}) {
  const { empresa: empresaLogada } = useAuth()

  const [fetchStatus, setFetchStatus] = useState("idle")
  const [data, setData] = useState([])
  const [isFirstQuery, setIsFirstQuery] = useState(true)

  const getData = useCallback(async () => {
    setFetchStatus("loading")

    try {
      const { data: { results } } = await api.get(
        `/empresas/${empresaLogada.id}/intercarg/faturamento/dashboard/faturamento-diario-empresa/${empresaFilial.id}`,
        {
          params: {
            dataPeriodo: periodo
          }
        }
      )

      setData(results)
    } catch (err) {
      notification.open({
        message: 'Erro!',
        description: "Ocorreu um erro ao realizar a busca"
      });
    } finally {
      setFetchStatus('idle')
    }

  }, [empresaFilial.id, empresaLogada.id, periodo])

  const onClose = useCallback(() => {
    closeFn()
  }, [closeFn])

  useEffect(() => {
    if (data.length === 0 && fetchStatus === "idle" && isOpen && isFirstQuery) {
      setIsFirstQuery(false)
      getData()
    }
  }, [getData, data, fetchStatus, isOpen, isFirstQuery])

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      visible={isOpen}
      title={
        <TituloTela codigoTela="02.76.1482" nomeTela="Faturamento Diário da Filial" />
      }
      width={"90vw"}
    >
      <Container>
        <Title>
          <h4 className="nome-empresa">ID - {empresaFilial.id} - {empresaFilial.nome}</h4>
        </Title>
        <Spin spinning={fetchStatus === "loading"}>
          <Content>
            {data.length &&
              <GrupoCards titulo={"Gráfico meta x faturamento diário - Filial"}>
                <GraficoFaturamentoDiario data={data} />
              </GrupoCards>
            }
            <TabelaFaturamentoDiario data={data} />
          </Content>
        </Spin>
      </Container>
    </Drawer>
  )
}