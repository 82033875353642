import React from "react"
import { columns, formatFaturamentoDiarioForTable } from "../../faturamentoDiarioHelper"
import { DataTable } from "../../../../../../components/Table/DataTable"

export function TabelaFaturamentoDiario({ data }) {
  const faturamentoDiarioFormatado = formatFaturamentoDiarioForTable(data)

  const tableConfig = {
    pagination: {
      pageIndex: 0,
      pageSize: 40
    },
    initialColumnOrdering: [{ id: 'dt', desc: false }],
  }

  return <DataTable
    columns={columns}
    dataSource={faturamentoDiarioFormatado}
    tableConfig={tableConfig}
  />
}