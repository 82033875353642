import React, { useCallback, useEffect } from "react";

import { Select, Button, Typography } from 'antd';

import { LeftOutlined, RightOutlined, DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';

import { Container, NavigationWrapper, GroupActionsWrapper } from './styles';

import useIsMobile from "../../../hooks/useIsMobile";

const opcoesSelectItensPorPagina = [5, 10, 20, 30, 40, 50].map(item => ({
	value: item,
	label: <Typography.Text>
		<Typography.Text strong>{item}</Typography.Text> {' '} por Página
	</Typography.Text>
}));

export function DataTablePagination({ dataTable }) {
	const isMobile = useIsMobile();

	const totalRegistrosTabela = dataTable.getFilteredRowModel().rows.length;

	const handleChangeSelect = useCallback((value) => {
		dataTable.setPageSize(Number(value));
	}, [dataTable])

	useEffect(() => {
		if (isMobile) {
			handleChangeSelect(totalRegistrosTabela);
			return;
		}
	}, [handleChangeSelect, isMobile, totalRegistrosTabela]);

	return (
		<Container>
			<Typography.Text>
				Total de itens: {` `}
				<Typography.Text strong>{dataTable.getFilteredRowModel().rows.length}</Typography.Text>
			</Typography.Text>

			{!isMobile &&
				<NavigationWrapper>
					<Select
						style={{ width: 140 }}
						defaultValue={dataTable.getState().pagination.pageSize}
						onChange={handleChangeSelect}
						options={opcoesSelectItensPorPagina}
					/>

					<Typography.Text>
						Página {dataTable.getState().pagination.pageIndex + 1} de {dataTable.getPageCount()}
					</Typography.Text>

					<GroupActionsWrapper>
						<Button
							icon={<DoubleLeftOutlined />}
							onClick={() => dataTable.setPageIndex(0)}
							disabled={!dataTable.getCanPreviousPage()}
						/>

						<Button
							icon={<LeftOutlined />}
							onClick={() => dataTable.previousPage()}
							disabled={!dataTable.getCanPreviousPage()}
						/>

						<Button
							icon={<RightOutlined />}
							onClick={() => dataTable.nextPage()}
							disabled={!dataTable.getCanNextPage()}
						/>

						<Button
							icon={<DoubleRightOutlined />}
							onClick={() => dataTable.setPageIndex(dataTable.getPageCount() - 1)}
							disabled={!dataTable.getCanNextPage()}
						/>
					</GroupActionsWrapper>
				</NavigationWrapper>
			}
		</Container>
	)
}