import React from 'react';
import { Card, Space, Typography, Progress } from 'antd';
import { formatDecimal } from '../../../../../utils/format-decimal';
import styled from 'styled-components';

const StyledProgress = styled(Progress)`
  .ant-progress-circle-trail {
    stroke: #78787830 !important;
  }

  .ant-progress-circle-path {
    stroke: #ffffff !important;
  }
`

export default function Meta({ meta, perc }) {

  return (
    <Card
      style={{ background: '#34D399', borderRadius: 8 }}
    >
      <Space direction='horizontal' size="large">
        <StyledProgress
          type='circle'
          percent={perc}
          width={75}
          trailColor='#fff'
          format={() =>
            <div style={{ color: '#fff', fontSize: "14px" }}>{formatDecimal(perc)}%</div>
          }
        />
        <Card.Meta
          title={
            <Typography.Title
              level={4}
              style={{
                color: '#fff',
                letterSpacing: 0.5
              }}
            >
              Meta mensal
            </Typography.Title >
          }
          description={
            <Typography.Text
              style={{ color: '#fff', fontSize: 24 }}
            >
              R$ {formatDecimal(meta)}
            </Typography.Text>
          }
        />
      </Space>
    </Card>
  )
}