import React from "react"

import { DataTableColumnHeader } from "../../../../components/Table/DataTableColumnHeader";
import { formatDateBrazil } from "../../../../utils/format-date-brazil";
import { formatDecimal } from "../../../../utils/format-decimal";

const capitalize = s => (s && String(s[0]).toUpperCase() + String(s).slice(1)) || ""

export const formatFaturamentoDiarioForChart = (faturamentoDiario) => {
  return faturamentoDiario.reduce((dados, current) => {
    if (!dados || dados.length === 0) {
      dados = []
    }
    
    dados.push({
      type: "Faturamento",
      date: formatDateBrazil(current.dt, "dd"),
      value: Number(current.valor_fat_dia),
      fullDate: formatDateBrazil(current.dt, "dd/MM/yyyy"),
      weekDay: capitalize(current.dia_semana),
      acum: Number(current.vlr_acumulado),
      percDiaria: Number(current.perc_meta_diaria),
      percAcum: Number(current.perc_meta_acumulada),
    })
    dados.push({
      type: "Meta",
      date: formatDateBrazil(current.dt, "dd"),
      value: Number(current.vlr_meta_diaria),
      fullDate: formatDateBrazil(current.dt, "dd/MM/yyyy"),
      weekDay: capitalize(current.dia_semana),
      percDiaria: Number(current.perc_meta_diaria),
      acum: Number(current.vlr_meta_acumulado),
      percAcum: Number(current.perc_meta_acumulada),
    })

    return dados
  }, [])
}

export const formatFaturamentoDiarioForTable = (faturamentoDiario) => {
  return faturamentoDiario.map((item) => {
    return ({
      ...item,
      formatados: {
        data: (
          <div
            style={{
              display: "flex",
              gap: "8px",
            }}
          >
            <div
              style={{
                width: "100%",
                maxWidth: "36px",
                fontSize: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid var(--first-color-font-lignt)",
                color: "var(--first-color-font-lignt)",
                borderRadius: "4px"
              }}
            >
              {capitalize(item.dia_semana).slice(0, 3)}.
            </div>
            {formatDateBrazil(item.dt, "dd/MM/yyyy")}{' '}
          </div>
        ),
        valor_fat_dia: formatDecimal(item.valor_fat_dia),
        vlr_acumulado: formatDecimal(item.vlr_acumulado),
        vlr_meta_diaria: formatDecimal(item.vlr_meta_diaria),
        vlr_meta_acumulado: formatDecimal(item.vlr_meta_acumulado),
        perc_meta_diaria: formatDecimal(item.perc_meta_diaria),
        perc_meta_acumulada: formatDecimal(item.perc_meta_acumulada)
      }
    })
  })
}

export const columns = [
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Data" />,
    cell: ({ row }) => <div style={{ whiteSpace: "nowrap" }}>{row.original.formatados.data}</div>,
    accessorKey: "dt",
    title: "Data",
    widthColumn: 120,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="V. Faturado (R$)" />,
    cell: ({ row }) => row.original.formatados.valor_fat_dia,
    accessorKey: "valor_fat_dia (R$)",
    title: "V. Faturado",
    widthColumn: 70,
    align: "right"
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="V. Meta (R$)" />,
    cell: ({ row }) => row.original.formatados.vlr_meta_diaria,
    accessorKey: "vlr_meta_diaria",
    title: "V. Meta (R$)",
    widthColumn: 70,
    align: "right"
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="% Meta Diária" />,
    cell: ({ row }) => 
      <p style={{ 
        color: row.original.perc_meta_diaria >= 100 ? '#16a34a' : '#9CA3AF',
        marginBottom: 0,
      }}>
        {row.original.formatados.perc_meta_diaria}
      </p>,
    accessorKey: "perc_meta_diaria",
    title: "% Meta Diária",
    widthColumn: 70,
    align: "right"
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="V. Fat. Acum. (R$)" />,
    cell: ({ row }) => row.original.formatados.vlr_acumulado,
    accessorKey: "vlr_acumulado",
    title: "V. Fat. Acum. (R$)",
    widthColumn: 70,
    align: "right"
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="V. Meta Acum. (R$)" />,
    cell: ({ row }) => row.original.formatados.vlr_meta_acumulado,
    accessorKey: "vlr_meta_acumulado",
    title: "V. Meta Acum. (R$)",
    widthColumn: 70,
    align: "right"
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="% Meta Acum." />,
    cell: ({ row }) => row.original.formatados.perc_meta_acumulada,
    accessorKey: "perc_meta_acumulada",
    title: "Data",
    widthColumn: 70,
    align: "right"
  },
]