import React from "react"
import { formatFaturamentoDiarioForChart } from "../../faturamentoDiarioHelper"
import { Line } from "@ant-design/charts"
import { formatDecimal } from "../../../../../../utils/format-decimal"

export function GraficoFaturamentoDiario({ data }) {
  const dadosFormatados = formatFaturamentoDiarioForChart(data)

  const linhasVerticais = dadosFormatados
    .map((item, index) => ({ ...item, index }))
    .filter(d => d.weekDay === 'Segunda-feira' && d.type === "Meta")
    .map((dia, index) => {
      return {
        type: 'line',
        start: (a, b) => {
          return [`${a.scale(dia.date) * 100}%`, '0%']
        },
        end: (a) => {
          return [`${a.scale(dia.date) * 100}%`, '100%']
        },
        top: true,
        style: {
          stroke: '#1890ff',
          lineWidth: 1,
          lineDash: [4, 4],
        },
        text: {
          // não renderiza o texto caso segunda seja o último dia do mês
          content: dia.date !== dadosFormatados.slice(-1)[0]?.date ? `${index + 1}ª semana` : '',
        }
      }
    });

  return <Line
    {...{
      data: dadosFormatados,
      xField: 'date',
      yField: 'value',
      seriesField: 'type',
      annotations: linhasVerticais,
      tooltip: {
        title: (_, original) => {
          return `${original.fullDate} (${original.weekDay})`;
        },
        customItems: (originalItems) => {
          if (originalItems?.length === 2) {
            const faturamento = originalItems[0];
            const meta = originalItems[1];
            return [
              { name: 'Faturamento Diário', value: `R$ ${formatDecimal(faturamento.value)}`, color: '#1C6FDA' },
              { name: 'Meta Diária', value: `R$ ${formatDecimal(meta.value)}`, color: '#FBB13C' },
              { name: '% Meta Diária', value: `${formatDecimal(meta.data.percDiaria)}%`, color: meta.data.percDiaria >= 100 ? '#8FD694' : 'transparent' },
              { name: 'Faturamento Acumulado', value: `R$ ${formatDecimal(faturamento.data.acum)}` },
              { name: 'Meta Acumulada', value: `R$ ${formatDecimal(meta.data.acum)}` },
              { name: '% Meta Acumulada', value: `${formatDecimal(meta.data.percAcum)}%`, color: meta.data.percAcum >= 100 ? '#8FD694' : 'transparent' },
            ];
          }
          return originalItems;
        }
      },
      yAxis: {
        label: {
          formatter: (v) => `R$ ${formatDecimal(v)}`
        },
      },
      xAxis: {
        line: {
          style: {
            stroke: '#aaa',
          },
        },
        tickLine: {
          style: {
            lineWidth: 2,
            stroke: '#aaa',
          },
          length: 5,
        },
      },
      colorField: 'type', // or seriesField in some cases
      color: ({ type }) => {
        if (type === 'Meta') {
          return '#FBB13C';
        }
        return '#57B8FF';
      }
    }}
  />
}